//
// solana.scss
// Use this to write your custom SCSS
//

code,
pre {
  padding: 0.33rem;
  border-radius: $border-radius;
  background-color: $gray-200;
  color: $black;
}

.badge.bg-primary, .btn-primary {
  color: $gray-900;
}

.table > :not(:first-child) {
  // Bootstrap v5.1 added this and dashkit doesn't handle it yet
  border-top: 0px solid currentColor;
}

ul.log-messages {
  padding: 0.66rem;
  margin: 1rem;
  border-radius: $border-radius;
  background-color: $gray-200;
  color: $black;
  list-style: none;
  min-height: 12.5rem;
  max-height: 20rem;
  overflow: auto;
  font-size: 0.8125rem;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}

.popover-container {
  position: relative;

  .popover {
    &.bs-popover-top {
      background-color: $dark;
      top: auto;
      bottom: 1.5rem;
    }

    &.right {
      left: auto;
      right: 0;
    }

    &.bs-popover-bottom {
      background-color: $dark;
      top: 1.5rem;
    }

    .popover-body {
      color: white;
    }

    .arrow.right {
      right: 1rem;
    }

    .arrow::after {
      border-top-color: $dark;
      border-bottom-color: $dark;
    }
  }
}

.modal-backdrop.disable-pointer-events {
  pointer-events: none;
}

.offcanvas {
  visibility: visible;
  // Bootstrap 5.1 updates modal-backdrop to a higher z-index
  z-index: 1060;
}

.c-pointer {
  cursor: pointer;
}

.v-hidden {
  visibility: hidden;
  max-height: 0;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.dropdown-exit {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.border-bottom-none {
  border-bottom: 0px;
}

.opacity-50 {
  opacity: 0.5;
}

.btn.input-group {
  input {
    cursor: pointer;

    &:focus {
      cursor: text;
    }
  }
}

.text-signature,
.text-address {
  font-size: 85%;
}

input.text-signature,
input.text-address {
  padding: 0 0.75rem;
}

h4.ix-pill {
  display: inline-block;
  padding: 5px;
  border-radius: $border-radius;
  margin-bottom: 2rem;
  margin-left: -5px;
  background-color: shift-color($info, $bg-soft-scale);
}

h4.slot-pill {
  display: inline-block;
  padding: 5px;
  border-radius: $border-radius;
  margin-left: -5px;
  background-color: shift-color($primary, $bg-soft-scale);
  margin-bottom: 1.5rem;
}

.list-group-item:last-child {
  &.ix-item,
  &.slot-item {
    border-bottom-width: 0px;
  }
}

.list-group:last-child .list-group-item:last-child {
  &.ix-item,
  &.slot-item {
    border-bottom-width: 1px;
  }
}

.list-group-item:first-child {
  &.ix-item,
  &.slot-item {
    border-top-width: 1px;
  }
}

.min-width-0 {
  min-width: 0;
}

.mb-last-0 {
  &:last-child {
    margin-bottom: 0 !important;
  }
}

.vertical-top {
  vertical-align: top !important;
}

.w-1 {
  width: 1%;
}

.line-height-md {
  line-height: 1.5rem;
}

.search-indicator {
  color: hsl(0, 0%, 60%);
  display: flex;
  padding: 8px 10px;
  padding-left: 14px;
  transition: color 150ms;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    color: hsl(0, 0%, 40%);
  }
}

.search-bar__control {
  border-radius: $border-radius !important;
  padding: 4px;
}

.search-bar__menu {
  border-radius: $border-radius !important;
}

.token-icon {
  width: 24px;
  height: 24px;
}

.forced-truncate {
  .address-truncate {
    max-width: 180px;
    display: inline-block;
    vertical-align: bottom;

    @include media-breakpoint-down(sm) {
      max-width: 120px;
    }
  }
}

.address-truncate,
.signature-truncate {
  @include media-breakpoint-down(lg) {
    max-width: 180px;
    display: inline-block;
    vertical-align: bottom;
  }

  @include media-breakpoint-down(sm) {
    max-width: 120px;
  }
}

p.tree,
ul.tree,
ul.tree ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

p.tree span.c-pointer {
  color: $primary-dark;
}

ul.tree ul {
  margin-left: 1em;
}

ul.tree li {
  margin-left: 0.35em;
  border-left: thin solid #808080;
}

ul.tree li:last-child {
  border-left: none;
}

ul.tree li:before {
  width: 1.4em;
  height: 0.6em;
  margin-right: 0.1em;
  vertical-align: top;
  border-bottom: thin solid #808080;
  content: "";
  display: inline-block;
}

ul.tree li:last-child:before {
  border-left: thin solid #808080;
}

div.inner-cards {
  margin: 1.5rem;

  .card {
    background-color: $gray-700-dark;
  }

  .table td {
    border-top: 1px solid $gray-800-dark;
  }
}

// work around for https://github.com/JedWatson/react-select/issues/3857
.search-bar__input {
  width: 100% !important;
  input {
    width: 100% !important;
  }
}

#chartjs-tooltip {
  opacity: 1;
  position: absolute;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, -105%);
  transform: translate(-50%, -105%);

  div.content {
    font-size: 12px;
    color: white;
    margin: 0px;
    background: rgba(0, 0, 0, 0.9);
    border-radius: 4px;
    text-align: center;
    overflow: hidden;
  }

  div.label {
    padding: 10px;
    background: #111;
  }

  div.value {
    padding: 10px;
    background: black;
    border-bottom: 1px solid #333;
  }

  &:after {
    display: block;
    position: relative;
    width: 0;
    height: 0;
    left: 50%;
    top: -1px;
    border-right: 10px solid transparent;
    border-top: 10px solid #111;
    border-left: 10px solid transparent;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    content: "";
  }
}

.dropdown-menu.token-filter {
  max-height: 20rem;
  overflow-y: auto;
}

.alert-danger.alert-scam {
  background: red;
  border: 1px solid red;
}

pre.data-wrap,
pre.json-wrap {
  max-width: 22rem;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

pre.json-wrap {
  max-width: 36rem;
}

.program-log-instruction-name {
  color: $white;
}

.staking-card {
  h1 {
    margin-bottom: 0.75rem;
    small {
      font-size: 1rem;
    }
  }
  h5 {
    margin-bottom: 0;
  }
  em {
    font-style: normal;
    color: $primary;
  }
}

p.updated-time {
  position: absolute;
  font-size: 0.66rem;
  margin: 0.375rem;
  right: 0;
  bottom: 0;
}

.change-positive {
  color: $success;
}

.change-negative {
  color: $warning;
}

.identicon-wrapper {
  display: flex;
}

.identicon-wrapper-small {
  margin-left: 0.4rem;
}

.creators-dropdown-button-width {
  width: 150px;
}

.creator-dropdown-entry {
  flex-flow: row wrap;
}

.creator-dropdown-header {
  max-width: 80%;
  flex: 0 0 80%;
}

.creator-dropdown-entry-address {
  @extend .creator-dropdown-header;
  overflow: hidden;
  text-overflow: ellipsis;
}

.art-error-image-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
}

.no-overflow-with-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// security-txt css hacks
.security-txt ul {
    list-style: none;
    text-align: right;
    margin: 0;
    padding-inline-start: 0;
}

.security-txt p, pre {
    text-align: left !important;
    margin: 0;
}

.security-txt a {
    white-space: nowrap;
}

.security-txt td {
    white-space: unset;
}

.security-txt code {
    white-space: pre-wrap;
    display: block;
}

.security-txt-link-color-hack-reee {
  color: white;
}